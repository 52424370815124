<template>
  <div class="mt-3">
    <div class="flex flex-wrap justify-between items-center mb-1">
      <div class="mb-4 md:mb-0 mr-4">
      </div>
      <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
        <div class="w-full sm:w-auto flex">
          <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>
        </div>
      </div>
    </div>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table :class="{ 'animate-pulse': table.loading }" :data="table.rows" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_termin">No. Termin</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tipe_angsuran">Tipe</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="Tgl. Due">Tgl. Due</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="persen">Persen (%)</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jml_nominal">Jumlah</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="dpp">DPP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jml_ppn">PPN</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jml_pph">PPh</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status">Status</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jml_bayar">Jml Terbayar</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="jml_sisa">Jml Sisa</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="keterangan">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Last Modified At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="font-bold" :class="['text-' + item.tipe_angsuran_color]">Ke - {{ item.no_termin }}</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white rounded-full" :class="['bg-' + item.tipe_angsuran_color]">
                {{ item.tipe_angsuran }}
              </span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr }}%</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_nominal | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_ppn | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_pph | idr}}</vs-td>
            <vs-td>
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_color]">{{ item.status }}</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_bayar | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_sisa | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.updated_at }}</vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr v-if="table.rows.length > 0" class="text-sm">
            <vs-td class="font-bold">Total</vs-td>
            <vs-td class="text-right font-bold" colspan="3" >{{ footerTermin.totalPercent | idr(2) }}%</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalNominal | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalDpp | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPpn | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPph | idr }}</vs-td>
            <vs-td class="text-right font-bold"></vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalTerbayar | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalSisa | idr }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

  </div>
</template>

<script>
import KontrakAngsuranRepository from '@/repositories/marketing/kontrak/kontrak-angsuran-repository'
import _ from 'lodash'

export default {
  name: 'TabAngsuranD',
  props: {
    isActive: { type: Boolean },
    selectable: { default: false, type: Boolean }
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    footerTermin () {
      return {
        totalPercent: _.sumBy(this.table.rows, item => item.persen),
        totalDpp: _.sumBy(this.table.rows, item => item.dpp),
        totalNominal: _.sumBy(this.table.rows, item => item.jml_nominal),
        totalPpn: _.sumBy(this.table.rows, item => item.jml_ppn),
        totalPph: _.sumBy(this.table.rows, item => item.jml_pph),
        totalTerbayar: _.sumBy(this.table.rows, item => item.jml_bayar),
        totalSisa: _.sumBy(this.table.rows, item => item.jml_sisa)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      errors: null,
      table: {
        loading: false,
        search: '',
        rows: []
      }
    }
  },
  methods: {
    initData () {
      this.getData()
      this.isDataInited = true
    },

    getData () {
      this.table.loading = true

      const idKontrak = this.$route.params.idKontrak
      KontrakAngsuranRepository.get(idKontrak)
        .then(response => {
          this.table.rows = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
